import React from "react";

export const RedirectionToOpRoomMessage = () => {
    return (
        <div style={{ marginTop: "20px" }}>
            This feature has been moved to the Operating room:{" "}
            <a href="https://hublo.retool.com/apps/1bc26980-6cea-11ee-a898-ffa6dd5256dd/Operating%20Room/Operating%20Room">
                https://hublo.retool.com/apps/1bc26980-6cea-11ee-a898-ffa6dd5256dd/Operating%20Room/Operating%20Room
            </a>
        </div>
    );
};
